















import { Component, Vue } from 'vue-property-decorator'
import Tabs from '@/app/ui/components/Tabs/index.vue'
import controller from '@/app/ui/controllers/RequestTopupSaldoController'
import RequestPointList from '@/app/ui/views/Adjustment/RequestPoin/List/index.vue'
import RequestPointHistoryBulky from '@/app/ui/views/Adjustment/RequestPoin/HistoryBulky/index.vue'

@Component({
  components: {
    Tabs,
    RequestPointList,
    RequestPointHistoryBulky,
  },
})
export default class RequestPoinPage extends Vue {
  controller = controller

  itemsTabs = [
    { label: 'List Customer', value: 'list-request' },
    { label: 'History Bulky', value: 'upload-bulky' },
  ]
  currentTab = 'list-request'

  created(): void {
    const queries = this.$route.query as Record<string, never>
    if (queries.tab) {
      this.currentTab = queries.tab
    }
  }

  private onTabsClick(tab: string) {
    this.currentTab = tab
    this.$router.replace({
      query: {},
    })
  }
}
