



















































































































import { Watch, Component, Vue } from 'vue-property-decorator'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import { Utils, REQUEST_TOP_SALDO_PAGINATION } from '@/app/infrastructures/misc'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import { DataObject } from '@/app/ui/components/DataTableV2/type'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import controller, {
  AdjustmentParcelPoinParamsInterface,
} from '@/app/ui/controllers/AdjustmentParcelPoinController'
import Card from '@/app/ui/components/Card/index.vue'
import IllustrationCreateFaq from '@/app/ui/assets/ill_create_faq.vue'
import ModalUploadBulkyAdjustmentParcelPoin from '@/app/ui/views/Adjustment/components/ModalUploadBulky/RequestPoin.vue'

type fakeDictionary<T> = { [key: string]: T }
@Component({
  components: {
    IllustrationCreateFaq,
    Card,
    LoadingOverlay,
    TextInput,
    PaginationNav,
    DataTableV2,
    Button,
    EmptyState,
    ModalUploadBulkyAdjustmentParcelPoin,
  },
})
export default class RequestPointList extends Vue {
  isSuperAdmin: boolean = Utils.isSuperAdmin()
  parameters: Record<string, string | number> = {
    page: 1,
    perPage: REQUEST_TOP_SALDO_PAGINATION,
    phoneNumber: '',
    tab: 'list-request',
  }
  headers: Array<Record<string, string>> = [
    {
      title: 'Customer ID',
    },
    {
      title: 'Phone Number',
    },
    {
      title: 'Total Poin',
    },
    {
      title: 'Action',
    },
  ]
  controller = controller
  showUploadModal = false

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = this.$route.query as Record<string, never>
      this.parameters = {
        phoneNumber: decodeURIComponent(queries.phoneNumber || ''),
        page: Utils.alwaysNumber(queries.page) || 1,
        perPage: REQUEST_TOP_SALDO_PAGINATION,
        tab: 'list-request',
      }
    }
    // call function to fetch all Data
    this.fetchListAdjustmentParcelPoin()
  }

  get params(): AdjustmentParcelPoinParamsInterface {
    return {
      page: this.parameters.page.toString(),
      perPage: this.parameters.perPage.toString(),
      phoneNumber: encodeURIComponent(this.parameters.phoneNumber).toString(),
      tab: 'list-request',
    }
  }

  get dataRequestPoin(): Array<DataObject[]> {
    if (this.controller.listAdjustmentParcelPoin.length > 0) {
      return this.controller.listAdjustmentParcelPoin.map(item => {
        return [
          {
            value: item.customerId,
          },
          {
            value: item.phoneNumber ? Utils.countryIndonesiaPhoneNumber(item.phoneNumber, true) : '-',
          },
          {
            value: Utils.currencyDigit(Number(item.totalPoint)),
          },
          {
            value: item.customerId,
          },
        ]
      })
    }
    return []
  }

  private onlyInputPhone(e: string): void {
    this.parameters.phoneNumber = Utils.phoneFormat(e)
  }

  @Watch('params')
  onParamsChanged(
    val: fakeDictionary<string | (string | null)[] | null | undefined>
  ): void {
    this.$router.replace({
      query: { ...val },
    })
  }

  private fetchListAdjustmentParcelPoin(resetPage?: boolean): void {
    if (resetPage) {
      this.parameters.page = 1
    }
    this.controller.getAll(this.params)
  }

  private actionModalUpload() {
    this.showUploadModal = true
  }

  private onDownloadTemplate(): void {
    window.open(
      'https://storage.googleapis.com/algo-prod/asset/template/TEMPLATE_BULK_REQUEST_PARCEL-POIN.xlsx'
    )
  }
}
