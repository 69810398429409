






































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { APPROVING_TOPUP_SALDO_PAGINATION, Utils } from '@/app/infrastructures/misc'
import controller, {
  AdjustmentParcelPoinParamsInterface,
} from '@/app/ui/controllers/AdjustmentParcelPoinController'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DateTimePickerV2 from '@/app/ui/components/DateTimePickerV2/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import { DataObject, HeaderObject } from '@/app/ui/components/DataTableV2/type'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import { Dictionary } from 'vue-router/types/router'

export interface queriesInterface {
  sortBy: string
  page: string
  startDate: Date | null | string
  endDate: Date | null | string
  search: string
}

export interface parametersInterface {
  page: number
  perPage: number
  tab: string
  search: string
  date: {
    start: Date | null
    end: Date | null
  }
  sortBy: {
    label: string
    value: string
  }
}

@Component({
  name: 'RequestPointHistoryBulkyPage',
  components: {
    LoadingOverlay,
    TextInput,
    DateTimePickerV2,
    DropdownSelect,
    DataTableV2,
    PaginationNav,
    EmptyState,
  },
})
export default class RequestPointHistoryBulkyPage extends Vue {
  controller = controller
  sortOptions = [
    { label: 'Terbaru', value: 'LATEST' },
    { label: 'Terlama', value: 'OLDEST' },
  ]
  parameters: parametersInterface = {
    page: 1,
    perPage: APPROVING_TOPUP_SALDO_PAGINATION,
    tab: 'upload-bulky',
    search: '',
    date: {
      start: null,
      end: null,
    },
    sortBy: this.sortOptions[0],
  }
  headers: Array<HeaderObject> = [
    {
      title: 'ID File',
      customStyle: { maxWidth: '120px', minWidth: '120px' },
    },
    {
      title: 'Nama File',
    },
    {
      title: 'Dikirim Oleh',
    },
    {
      title: 'Tanggal Kirim',
    },
    {
      title: 'Data Failed',
    },
  ]

  created(): void {
    const queries = <queriesInterface>(<unknown>this.$route.query)
    let startDate = null
    let endDate = null
    const checkInvalidStartDate = queries.startDate
      ? new Date(queries.startDate)
      : null
    const checkInvalidEndeDate = queries.endDate
      ? new Date(queries.endDate)
      : null
    // to check Invalid Date
    if (
      checkInvalidStartDate?.toString() !== 'Invalid Date' &&
      checkInvalidEndeDate?.toString() !== 'Invalid Date'
    ) {
      if (queries.startDate !== '' && queries.endDate !== '') {
        if (
          <Date>checkInvalidStartDate >= this.minDate &&
          <Date>checkInvalidStartDate <= this.maxDate &&
          <Date>checkInvalidEndeDate <= this.maxDate &&
          <Date>checkInvalidEndeDate >= this.minDate
        ) {
          startDate = checkInvalidStartDate
          endDate = checkInvalidEndeDate
        }
      }
    }
    this.parameters = {
      sortBy:
        this.sortOptions.find((item) => item.value === queries.sortBy) ||
        this.sortOptions[0],
      page: Utils.alwaysNumber(queries.page) || 1,
      perPage: APPROVING_TOPUP_SALDO_PAGINATION,
      tab: 'upload-bulky',
      date: {
        start: startDate,
        end: endDate,
      },
      search: queries.search,
    }

    this.fetchHistoryUploadBulky(true)
  }

  get maxDate(): Date {
    const maxDate = new Date()
    maxDate.setHours(7, 0, 0, 0)
    return maxDate
  }

  get minDate(): Date {
    const currentDate = new Date()
    const getTreeMonthAgo = currentDate.getMonth() - 3
    const setTreeMonthAgo = currentDate.setMonth(getTreeMonthAgo)
    const minDate = new Date(setTreeMonthAgo)
    minDate.setHours(7, 0, 0, 0)
    return minDate
  }

  get historyBulkData(): Array<DataObject[]> {
    return this.controller.dataHistoryUploadBulkyParcelPoin.map((item) => {
      return [
        {
          value: item.id,
          customStyle: { maxWidth: '120px', minWidth: '120px' },
        },
        {
          value: item.filename,
        },
        {
          value: item.actor,
        },
        {
          value: Utils.formatTimeZone(
            Utils.formatDate(item.createdAt, 'DD MMM YYYY, HH:mm:ss Z')
          ),
        },
        {
          value:
            item.failedCustomerIDs?.length > 0
              ? String(item.failedCustomerIDs.map((item) => `[${item}]`))
              : '-',
        },
      ]
    })
  }

  get params(): AdjustmentParcelPoinParamsInterface {
    const { page, perPage, tab, search, sortBy } = this.parameters

    const newParameters = {
      page,
      perPage,
      tab,
      search,
      sortBy,
    }

    return {
      ...newParameters,
      sortBy: this.parameters.sortBy.value,
      startDate: this.parameters.date.start
        ? Utils.formatDateWithIDLocale(
            new Date(this.parameters.date.start)?.toISOString(),
            'YYYY-MM-DD'
          )
        : '',
      endDate: this.parameters.date.end
        ? Utils.formatDateWithIDLocale(
            new Date(this.parameters.date.end)?.toISOString(),
            'YYYY-MM-DD'
          )
        : '',
    }
  }

  @Watch('params')
  onParamsChanged(val: Dictionary<string | (string | null)[] | null | undefined> | undefined): void {
    this.$router
      .replace({
        query: { ...val },
      })
      .catch(() => false)
  }

  fetchHistoryUploadBulky(resetPage = false): void {
    if (resetPage) {
      this.parameters.page = 1
    }
    this.controller.getHistoryUploadBulky(this.params)
  }
}
